import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Typography from 'components/ui-components-v2/Typography';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import './styles/main.scss';

export interface MenuWithHelperTextItem {
    /**
     * Unique key for the menu item.
     * This is used for testing purposes.
     */
    key?: string;
    /**
     * React component to be displayed in the menu item.
     */
    component?: React.ReactNode;
    /**
     * Label for the menu item.
     */
    label?: string;
    /**
     * Icon for the menu item.
     */
    icon?: string;
    /**
     * End icon for the menu item.
     */
    endAdornment?: React.ReactNode;
    /**
     * Tooltip for the menu item.
     */
    tooltip?: string;
    /**
     * Helper text for the menu item.
     */
    helperText?: string;
    /**
     * Hide the menu item.
     */
    hide?: boolean;
    /**
     * Disable the menu item.
     */
    disabled?: boolean;
    /**
     * If the menu item is selected.
     */
    selected?: boolean;
    /**
     * Function to be called when the menu item is clicked.
     */
    onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    /**
     * Class name for the menu item.
     */
    className?: string;
}

export interface MenuWithHelperTextProps {
    /**
     * If the menu can be opened.
     * @default true
     */
    canBeOpened?: boolean;
    /**
     * If the menu is open.
     * This way the menu can be controlled from the parent component.
     */
    open?: boolean;
    /**
     * Menu trigger element.
     * The onClick event will be overridden.
     */
    trigger?: React.ReactElement;
    /**
     * Position of the dropdown.
     * If not provided, the dropdown will be displayed below the trigger element.
     */
    dropdownPosition?: { top: number | null; left: number | null };
    /**
     * Menu items to be displayed.
     */
    items: MenuWithHelperTextItem[];
    /**
     * Function to be called when the menu is opened.
     * @param event - Event from trigger click.
     */
    onOpen?: (event: React.MouseEvent<HTMLDivElement>) => void;
    /**
     * Function to be called when the menu is closed.
     */
    onClose?: () => void;
    /**
     * If the menu should stay open.
     */
    stayOpen?: boolean;
    /**
     * Minimum width of the menu.
     */
    minWidth?: number;
    /**
     * Data-cy prefix for testing.
     */
    dataCyPrefix?: string;
}

/**
 * The MenuWithHelperText component is a wrapper around the Menu component. It displays a list of clickable items with helper text beneath it.
 *
 * - [MenuWithHelperText documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/621051905/MenuWithHelperText)
 */
const MenuWithHelperText = ({
    canBeOpened = true,
    open,
    trigger,
    dropdownPosition,
    items,
    onOpen,
    onClose,
    stayOpen,
    minWidth,
    dataCyPrefix
}: MenuWithHelperTextProps) => {
    /**
     * State to keep track of the hovered value.
     */
    const [hoveredValue, setHoveredValue] = useState<string | null>(null);

    /**
     * State to keep track of the menu anchor element.
     */
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    /**
     * Reference to the widest div in the menu.
     */
    const widestMenuItem = React.useRef<{ current: HTMLElement | null; width: number }>({ current: null, width: 0 });

    const menuOpen = open ?? Boolean(menuAnchorEl);

    const anchorReference = dropdownPosition?.top && dropdownPosition.left ? 'anchorPosition' : undefined;
    const anchorPos =
        dropdownPosition && dropdownPosition.top !== null && dropdownPosition.left !== null
            ? { top: dropdownPosition.top ?? 0, left: dropdownPosition.left ?? 0 }
            : undefined;

    /**
     * Menu items to be displayed.
     * Filter out items that are hidden.
     */
    const menuItems = useMemo(() => {
        if (!items) return [];
        return items.filter((item) => !item.hide);
    }, [items]);

    /**
     * Open the input options menu.
     * @param event - Event from event listener.
     */
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        if (menuItems.length === 0) return;
        setMenuAnchorEl(event.currentTarget);
    };

    /**
     * Close the input options menu.
     */
    const handleClose = () => {
        if (stayOpen) return;
        setMenuAnchorEl(null);
        onClose?.();
    };

    const newHoveredValue = useMemo(() => {
        if (!hoveredValue) return null;

        return DOMPurify.sanitize(hoveredValue);
    }, [hoveredValue]);

    return (
        <>
            {trigger &&
                React.cloneElement(trigger, {
                    onClick: (event) => {
                        if (!canBeOpened) return;
                        event.stopPropagation();
                        event.preventDefault();
                        onOpen?.(event);
                        handleOpen?.(event);
                    }
                })}
            <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={() => {
                    setMenuAnchorEl(null);
                    onClose?.();
                }}
                anchorReference={anchorReference}
                anchorPosition={anchorPos}>
                {menuItems.map((item, index) => (
                    <Tooltip key={index} title={item.tooltip} disableInteractive placement="left">
                        <MenuItem
                            data-cy={`${dataCyPrefix}MoreMenu${item.key}-button`}
                            className={classNames(item.className)}
                            disabled={item.disabled}
                            selected={item.selected}
                            onClick={(event) => {
                                event.stopPropagation();
                                item.onClick?.(event);
                                handleClose();
                            }}
                            onMouseEnter={() => {
                                if (!item.helperText) return;
                                setHoveredValue(item.helperText);
                            }}
                            ref={(el) => {
                                if (el && el.offsetWidth > (widestMenuItem.current?.current?.offsetWidth || 0)) {
                                    if (minWidth && el.offsetWidth < minWidth) {
                                        widestMenuItem.current = { current: el, width: minWidth };
                                        return;
                                    }

                                    widestMenuItem.current = { current: el, width: el.offsetWidth };
                                }
                            }}>
                            {item.component && item.component}

                            {item.icon && !item.component && (
                                <Icon fontSize="small" className="ui-v2-cape-menu-with-helper-text__menu__icon">
                                    {item.icon}
                                </Icon>
                            )}
                            {!item.component && (
                                <Typography
                                    variant="caption"
                                    className={classNames('ui-v2-cape-menu-with-helper-text__menu__label', {
                                        'ui-v2-cape-menu-with-helper-text__menu__label--extra-space': item.endAdornment
                                    })}>
                                    {item.label}
                                </Typography>
                            )}
                            {item.endAdornment && !item.component && (
                                <div className="ui-v2-cape-menu-with-helper-text__menu__end-adornment">{item.endAdornment}</div>
                            )}
                        </MenuItem>
                    </Tooltip>
                ))}
                {newHoveredValue && widestMenuItem.current && (
                    <div
                        className="ui-v2-cape-menu-with-helper-text__helper-text"
                        style={{ width: widestMenuItem.current.width }}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(newHoveredValue)
                        }}
                    />
                )}
            </Menu>
        </>
    );
};

export default MenuWithHelperText;
