import React from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';

//Not solved: typing when component is an anchor element
type IconButtonProps = Omit<MuiIconButtonProps, 'classes' | 'sx'> & { href?: string; target?: string };

/**
 * Renders a MUI IconButton component.
 *
 * - [MUI IconButton documentation](https://mui.com/material-ui/api/icon-button/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ ...props }: IconButtonProps, ref) => {
    return <MuiIconButton ref={ref} {...props} />;
});

IconButton.displayName = 'IconButton';

export default IconButton;
